@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.contatain {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .content-wrap {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  }
  .tab {
    display: flex;
    margin-bottom: 8px;
    &-item {
      width: 96px;
      height: 24px;
      border-radius: 4px;
      font-size: 18px;
      text-align: center;
      font-weight: 400;
      line-height: 24px;
      color: #000000;
      cursor: pointer;
      margin-right: 35px;
    }
    &-item-active {
      color: #fff;
      background: #7f76bd;
    }
  }
}
.save {
  z-index: 1000;
  position: absolute;
  bottom: 5px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  .save-btn {
    width: 96px;
    color: #fff;
    font-size: 16px;
    margin-left: 20px;
    font-weight: 500;
  }
}
.addImg {
  width: 18px;
  height: 24px;
  background-image: url("../../assets/add.png");
  cursor: pointer;
}
.banner-img-choice {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #c5c0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #333333;
  font-family: "SourceHanSansCN-Regular, SourceHanSansCN";
}
.flex-around {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex {
  display: flex;
}
.flex-start {
  display: flex;
  align-items: center;
}
.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 100px;
}
.first-title {
  color: #333333;
  font-size: 14px;
  margin-right: 20px;
}

.content {
  flex: 1;
  background: #fff;
  width: 100%;

  // margin-top: 8px;
  // height: calc(100% - 28px);
  height: 100%;
  box-sizing: border-box;
  padding: 32px 8px;
  color: #333333;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
  .item {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
  }
  .label {
    width: 80px;
    height: 24px;
    background: #eceafa;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    margin-right: 28px;
  }
}
.item-image {
  width: 149px;
  height: 56px;
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  .left {
    position: absolute;
    top: -5px;
    left: 0;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 54px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
  }
  .item-image-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }
}
v-deep .el-textarea__inner {
  margin: 0;
}
