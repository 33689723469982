@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.banner-img {
  &-choice {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #c5c0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #333333;
    font-family: "SourceHanSansCN-Regular, SourceHanSansCN";
  }
  &-leftbtn {
    position: absolute;
    bottom: -15px;
    left: 0%;
    transform: translate(0%, 100%);
  }
  &-rightbtn {
    position: absolute;
    bottom: -15px;
    right: 0%;
    transform: translate(0%, 100%);
  }
  &-tip::after {
    content: "*";
    position: absolute;
    font-size: 20px;
    top: 50%;
    right: -20%;
    transform: translate(0%, -50%);
    color: red;
  }
}

.flex-around {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex {
  display: flex;
}
.flex-start {
  display: flex;
  align-items: center;
}
.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 100px;
}
.first-title {
  color: #333333;
  font-size: 14px;
  margin-right: 20px;
}
.item-image {
  width: 149px;
  height: 56px;
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  .left {
    position: absolute;
    top: -5px;
    left: 0;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 54px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
  }
  .item-image-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }
}
v-deep .el-textarea__inner {
  margin: 0;
}
